import React from 'react'
import {Helmet} from 'react-helmet'
import ModalImage from 'react-modal-image-responsive'
import Layout from '../components/layout'
import './cascade.css'
import CascadeImage from '../images/cascade - search.png'
import CascadePortfolioImage from '../images/cascade/cascade - my portfolio.png'
import CascadeOnboardingImage from '../images/cascade/cascade - onboarding.png'
import CascadeComparisonImage from '../images/cascade/cascade - compare - zoom.png'
import CascadeProjectListingImage from '../images/cascade/cascade - project listing cropped.png'
import CascadeBuyingProcessImage from '../images/cascade/Cascade Buying Process.png'
import CascadeMarketingImage from '../images/cascade/Cascade - Marketing Page.png'
import CascadeNegotiationsImage from '../images/cascade/Cascade - Negotiations 2.png'
// import PWImage from '../images/portfolio-workbench-fund.png'
// import CascadeSearchCheckedImage from '../images/cascade/cascade - search - checked.png'

export default function Cascade() {
  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <meta name="robots" content="noindex" />
        <title>Leland Riordan - Portfolio - Cascade</title>
      </Helmet>
      <div className="case-study-feature">
        <img src={CascadeImage} className="case-study-feature-image" alt='Cascade Search Results'/>
      </div>
      <div className="main-content case-study-content">
      <div className="row row-max">
          <h1 className="case-study-title">Cascade</h1>
          <p className="lead">
            Cascade transforms the ESG energy market by enabling large corporations to buy direct access to green renewable energy.
            In the fall of 2021, I rapidly designed and prototyped the Cascade platform for its initial pitch to investors leading to a $25+ million seed round.
          </p>
        {/* </div>

        <div className="row row-max"> */}
          <h2>Introduction</h2>
          <p>
            Why does only 3% of energy consumed by corporations come from renewable sources?
            The answer is simple: the buying process is broken.
            The average large green energy purchase takes 12 months of complex negotiations and due diligence.
            This adds millions in legal and consulting fees to each transaction and creates a large barrier to entry for companies looking to go green and limits the market to Fortune 100 corporations.
          </p>              
          <p>
            In 2021, I was hand picked by Citi executives to design Cascade - a new green energy marketplace/trading platform that aims to streamline this process down to a week by revolutionizing search, standardizing terms, 
            and providing easy access to capital.
            By lowering the cost and shortening the time per transaction, more and more companies could make the transition to renewables.
            In the long term, the goal is to commoditize the green energy market so that these trades could be executed in high frequency like mature commidities such as oil or gas.
          </p>
          <ModalImage
            small={CascadeMarketingImage}
            large={CascadeMarketingImage}
            className="case-study-image"
            hideDownload={true}
            hideZoom={true}
            alt="Cascade Pre-Login Marketing Site Concept"
          />
        </div>

        <div className="row row-max">
          <h2>My Role</h2>

          <p>
            When I joined the project, it was little more than an idea that came from one of the leading commidities traders as Citi.
            While the executive team loved the initial idea they were having trouble conceptualizing the platform.
            I was brought in to work with the trader to take the concept and create a true product strategy and vision.
            We were given three weeks to prep a pitch for the executives, Citi Ventures and senior investment bankers with the hope of raising $20 to $30 million in initial seed funding.
          </p>
          <p>
            This was a top secret new product in stealth mode so we were under strict orders to keep the team small.
            I acted as head of product design and would focus on creating an interactive prototype of the platform that would serve as the centerpiece of the pitch.
            The trader acted like a CEO and focused on the financials, the pitch deck and regulatory requirements.
            Citi's Head of Commodity Trading and Head of Innovation acted as our mentors providing invaluable advice in the run up to the pitch.
          </p>
        </div>

        <div className="figure-container">
          <figure>
            <figcaption>Cascade Buying Process</figcaption>
            <ModalImage
              small={CascadeBuyingProcessImage}
              large={CascadeBuyingProcessImage}
              className="case-study-image"
              hideDownload={true}
              hideZoom={true}
              alt="Cascade Buying Process"
            />
          </figure>
        </div>

        <div className="row row-max">
          <h2>Design Process & Strategy</h2>

          <p>
            In terms of strategy, we wanted Cascade to own the ESG energy marketplace and become the New York Stock Exchange of green energy trading.
            If we built it in-house as a Citi product, then other top banks would attempt to build competitors and the market would fragment.
            Thus, we made a key part of our strategy to design Cascade with the intention that it would become an independent company.
            Citi would incubate Cascade to an MVP stage, and then spin it off after raising a Series A from partner banks and investors.
            To fend off potential competitors and increase marketshare, we would charge lower transaction fees and utilize Citi's existing trading relationships with the largest suppliers to onboard more projects onto the platform.
            The key differentiator for Cascade would be the streamlined buyer experience and therefore we made it the focus for the pitch.
          </p>
          <p>
            To kick off the design process, I led a long user research session with Citi's commodities traders to learn the ins and outs of the green energy buying process.
            Next, I conducted a long whiteboard session with the product team where we dug into each component of the user journey and made a strategy to address its problems.
            We only had three weeks to prepare so we needed to make fast product decisions.
            If we got the funding, we would revisit the details and conduct user research to iron out the kinks.
          </p>

          {/* 
          <h4>Buying Process</h4>
          <ol>
            <li>Search: Matching Buyers With The Right Project</li>
            <li>Streamlining Negotiations</li>
            <li>Post Trade: Portfolio Analysis</li>
            <li>Industry Knowledge & Learning</li>
          </ol>
          */}

          {/* <p>
            We wanted Cascade to own the ESG energy marketplace and develop into a true centralized commodities trading platform.
            The dream was to become the New York Stock Exchange or Nasdaq for green energy.
            If we built it in-house, then other firms would attempt to build competitors and the market would fragment.
            Thus, we made a key part of our strategy to design Cascade as an independent company from Citi.
            We would pitch outside investors and other large banks in an attempt to find a set of founding partners.
            This was a great idea but made my life difficult because I couldn't really utilize our internal design system.
          </p> */}
        </div>

        <div className="figure-container">
          <figure>
            <figcaption>Cascade Search Results</figcaption>
            <ModalImage
              small={CascadeImage}
              large={CascadeImage}
              className="case-study-image"
              hideDownload={true}
              hideZoom={true}
              alt="Cascade Search Results"
            />
          </figure>
        </div>

        <div className="row row-max">
          <h2>Search & Pre-Trade Analysis</h2>
          <p>
            Finding the right renewable energy project was a manual tedious affair.
            Buyers were forced to sift through a myriad of poor quality supplier sites each using its own metrics and analytics.
            This made apples-to-apples comparisons impossible without Excel and a lot of determination.
          </p>
        {/* </div>
        <div className="row row-max"> */}
          {/* <img src={CascadeImage} className="case-study-image" alt='Cascade Project Search Results'/> */}
          <h4>Search Results & Filters</h4>
          <p>
            Location is far and away the most important criteria for search experience.
            The platform is useless unless power is delivered to the right location.
            Thus, I designed the core search UX around a large map that lays out each project similar to AirBnB.
            To make quick filtering seamless, I exposed the five most used filters along the top of of the page.
            And then added an "All Filters..." button that opens a modal to enable users to drill down with more granularity.
          </p>

          <hr />

          <ModalImage
            small={CascadeComparisonImage}
            large={CascadeComparisonImage}
            className="case-study-image mb-2"
            hideDownload={true}
            hideZoom={true}
            alt="Cascade Project Comparison Table"
          />

          {/* <img src={CascadeComparisonImage} className="case-study-image" alt='Cascade Comparison Table'/> */}
          <h4>Advanced Comparisons</h4>
          <p>
            The ability to quickly compare projects across standard criteria is a key differentiator for Cascade's search experience.
            Users can just check off projects and then instantly compared them across a customizeable set of data and analytics.
            To make metrics easily comparable at a glance, I designed inline bullet charts that clearly showed the the strengths and weaknesses of each project.
            Advanced users could then expand the bullet chart rows to see the underlying data.
          </p>
          <hr />

          <ModalImage
            small={CascadeProjectListingImage}
            large={CascadeProjectListingImage}
            className="case-study-image mb-2"
            hideDownload={true}
            hideZoom={true}
            alt="Cascade Project Profile Page"
          />
          {/* <img src={CascadeProjectListingImage} className="case-study-image" alt='Portfolio Workbench Individual Fund Page'/> */}
          <h4>Product Pages</h4>
          <p>
            Product pages need to be both approachable for novice buyers while also powerful enough that a seasoned veteran could do advanced analysis.
            I worked with the commodities trading team to organize the available data into meaningful geographic, ESG and financial analytics.
            For new users we linked corresponding learning materials so they could learn as they browsed.
            For power users we added the ability to click into each analytic for more data and information.
            Last, I designed a sticky sidebar that allowed users to estimate costs, configure add-ons and kick off the deal making process.
          </p>
        </div>

        <div className="figure-container">
          <figure>
            <figcaption>Cascade Negotiation Dashboard</figcaption>
            <ModalImage
              small={CascadeNegotiationsImage}
              large={CascadeNegotiationsImage}
              className="case-study-image"
              hideDownload={true}
              hideZoom={true}
              alt="Cascade Negotiation Dashboard"
            />
          </figure>
        </div>

        <div className="row row-max">
          <h2>Negotiations & Due Diligence</h2>

          <p>
            The most time consuming and expensive portion of the existing buying process are the negotiations and due diligence between the buyers and suppliers.
            Since there are no standardized contracts, every transaction requires the creation of custom contracts with bespoke terms and conditions.
            Worse yet, since there is no trust between the buyer and seller, buyers were forced to hire expensive consulting firms to vet and audit suppliers.
          </p>

          {/* <img src={CascadeNegotiationsImage} className="case-study-image" alt='Portfolio Workbench Individual Fund Page'/> */}

          <p>
            While in the long term, we wanted to completely standardize the contracts to remove all negotiations, this wasn't realistic given the current level of maturity of the green energy market.
            As an interim step for the MVP, we decided to partner with a top law firm, to create standardized contract templates for each type of transaction.
            Buyers and sellers would still be able to negotiate but only over pre-defined terms and conditions.
            Once a buyer selects a project, they would fill out a quick checkout form.
            The system would generate contracts based off their selections and then negotiations over the terms would begin.
            I designed a dashboard to act as the centerpoint of these negotiations that would facilitate collaboration between the two teams, guiding them through the process.
          </p>

          <p>
            To fix the trust issue we would provide due diligence when onboarding buyers and suppliers, completely eliminating the need for the existing two month process.
            Buyers and sellers would fill out organizational profiles as part of the onboarding questionnaire.
            The system would then verify each side, leveraging Citi's existing fraud prevention and KYC (Know Your Customer) infrastructure before the parties are allowed to complete the transaction.
          </p>

          <ModalImage
            small={CascadeOnboardingImage}
            large={CascadeOnboardingImage}
            className="case-study-image"
            hideDownload={true}
            hideZoom={true}
            alt="Cascade Onboarding Wizard"
          />
        </div>

        <div className="row row-max">
          <h2>Post Trade Analytics</h2>

          <p>
            In the current marketplace, after a trade is completed, the buyer is stuck manually calculating metrics.
            On Cascade, our longterm goal is to create a live dashboard and analytics suite that provides users with a complete picture into both their financial as well as ESG metrics.
            With time running out before the pitch however, I only had time to quickly mockup one example page.
            I pulled together a quick design of a representative dashboard to sell the dream and we planned to revisit the details in the future.
          </p>

          <img src={CascadePortfolioImage} className="case-study-image" alt='Portfolio Workbench Individual Fund Page'/>
        </div>

        {/* <div className="row row-max">
          <h4>Industry Knowledge & Learning</h4>
          <p>
            Our goal is to open the green energy market up to any company and take away the boundaries that made it previously only possible for the Fortune 100.
            While streamlining the buying and negotiations processes would drastically lower the barrier to entry, after talking to the internal team at Citi responsible for purchasing green energy, we realized that there was still a mountain of industry knowledge that new buyers would need to learn in order to make intelligent investment decisions.
            We realized that Cascade would need to teach users about the ESG market and help guide them to make the right investment decisions.
            Cascade we would be lowering the barrier to entry for renewable energy purchases but these buyers lacked industry knowledge to make informed buying decisions.
            </p>

          <p>
            We came up with the concept of Knowledge Blocks. We would enlist industry leaders to create videos and publications that break down everything for everything a user would need to know about the ins and outs of buying renewable energy.
            We would then deeply integrate these Knowledge Blocks into the platform to give users the contextual information they need to make the right selections.
          </p>
        </div> */}

        <div className="row row-max">
          <h2>Outcome</h2>
          <p>
            We made our investor pitch in the fall of 2021 and successfully raised $25+ million from Citi to build an MVP.
            My prototype was critical to the presentation, allowing the investors to fully grasp the concept and click through the buying experience.
            The executives decided to incubate the company in house, and then open it up for outside investors and potential partners at the MVP launch.
            The MVP is planned to launch at the end of 2022 or in the first quarter 2023.
          </p>
          <p>
            On the product side of things, we have been meeting with potential seller, analytics and legal partners, starting the hiring process with the hunt for CTO and digging into the stringent compliance rules.
            On the UX side of things, we have been going a level deeper and starting to design out the ins and outs of the platform and experience.
            We have turned our focus to filling out the seller experience while conducting user research to refine the buying experience.
          </p>
        </div>
      </div>
    </Layout>
  )
}
